import React from 'react';
import PropTypes from 'prop-types';

import ActiveFilterItem from './ActiveFilterItem';

export const ActiveFilterListEW = (
  {
    categoryIds,
    categories,
    onFilterClick
  }
) => {

  return (
    categories && categories.map( (category, i) => {
    const checked = categoryIds.indexOf(category.id) !== -1;

    return (
      checked &&
      <ActiveFilterItem
        category={category}
        onFilterClick={onFilterClick}
      />
    )
   })
  );
}

ActiveFilterListEW.displayName = 'ActiveFilterListEW';
ActiveFilterListEW.propTypes = {
  categoryIds: PropTypes.array,
  categories: PropTypes.array,
  onFilterClick: PropTypes.func
};