/**
 * Created by Nikolai Gorte
 * User: n.gorte on 10.10.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import Siema from 'siema';

const defaultOptions = {
  loop: true,
  draggable: false
};

Object.defineProperty(Siema.prototype, 'buildSliderFrameItem', {
  value: function (elm) {
    const elementContainer = document.createElement('div');

    elementContainer.style.cssFloat = this.config.rtl ? 'right' : 'left';
    elementContainer.style.float = this.config.rtl ? 'right' : 'left';
    elementContainer.style.width = `${this.selectorWidth / this.perPage}px`; // fix

    elementContainer.appendChild(elm);

    return elementContainer;
  }
});

class CarouselBITBW extends React.Component {
  constructor (props) {
    super(props);

    this.identifier = 'siema_' + Math.random().toString(20).substr(2, 6);

    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount () {
    const { children, options } = this.props;

    this.siema = new Siema({
      ...defaultOptions,
      selector: '#' + this.identifier,
      perPage: {
        300: Math.min(children.length, 1),
        560: Math.min(children.length, 2),
        768: Math.min(children.length, options.perPage || 2)
      }
    });
  }

  componentWillUpdate () {
    this.siema && this.siema.destroy(true);
  }

  componentDidUpdate (prevProps, prevState) {
    const { children, options } = this.props;

    this.siema = new Siema({
      ...defaultOptions,
      perPage: {
        300: Math.min(children.length, 1),
        560: Math.min(children.length, 2),
        768: Math.min(children.length, options.perPage || 2)
      }
    });
  }

  componentWillUnmount () {
    this.siema && this.siema.destroy(true);
  }

  next () {
    this.siema && this.siema.next();
  }

  prev () {
    this.siema && this.siema.prev();
  }

  render () {
    const { children, options } = this.props;

    return (
      <div className={'event-container event-container--bitbw'}>
        <div id={this.identifier} className={'siema clearfix teaser-cnt-' + options.perPage} data-items={options.perPage}>
          {children}
        </div>

        {
          children.length > options.perPage &&
          <span className="xdicon cleft btn-slider">
            <span
              className="icon-bitbw-arrow-left"
              onClick={this.prev}
            />
          </span>
        }
        {
          children.length > options.perPage &&
          <span className="xdicon cright btn-slider">
            <span
              className="icon-bitbw-arrow-right"
              onClick={this.next}
            />
          </span>
        }
      </div>
    );
  }
}


CarouselBITBW.propTypes = {
  options: PropTypes.object
};

export default CarouselBITBW;
