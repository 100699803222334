/**
 * User: Niko
 * Date: 13.09.2020
 * Time: 21:40
 */

import React from 'react';
import PropTypes from 'prop-types';

export const List = ({ className, direction = 'vertical', children }) => {

  let listClassNames = `xdlist xdlist--${direction}`;
  listClassNames = className ? `${listClassNames} ${className}` : listClassNames;

  return (
    <ul className={listClassNames}>
      {children}
    </ul>
  );
};

List.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['vertical', 'horizontal'])
};
