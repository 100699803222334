/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import dayjs from 'dayjs';
import CategoryIcons from '../CategoryIcons';
import ListItemImage from './ListItemImage';
import { ShareButton } from '../EventActions/ShareButton';
import { SignUpButton } from '../EventActions/SignUpButton';
import { CustomLink } from '../CustomLink';
import { LIST_ITEM_DATE_FORMAT, SHORT_DATE_FORMAT } from '../../constants/Constants';

const EventListItemDefault = ({ event, showCity }) => {
  const {
    startTime, endTime, uuid, title, short_title, small_image_url, offering,
    vocabularies, Bildnachweis, AltTag, description, irregularAppointments
  } = event;


  //Strip HTML per REGEXP
  const descriptionText = description && description.replace(/<\/?[^>]+(>|$)/g, '');

  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);
  let dateString = `${startDate.format(LIST_ITEM_DATE_FORMAT)}, bis ${endDate.format(LIST_ITEM_DATE_FORMAT)}`;
  if (startDate.isSame(endDate, 'day')) {
    dateString = startDate.format(LIST_ITEM_DATE_FORMAT);
  } else if (irregularAppointments) {
    dateString = `ab ${startDate.format(SHORT_DATE_FORMAT)}`;
  }

  return (
    <li className="event-item">
      <section className="event-item__data">
        <ListItemImage
          uuid={uuid}
          title={title}
          src={small_image_url}
          alt={AltTag}
          copyright={Bildnachweis}
        />

        <div className="data-head">
          <span className="meta">{!offering && dateString}</span>
          <div className="meta-links meta-links--right icon-small">
            {showCity && event.city &&
              <div>
                <span className='meta'>{event.city}</span>
              </div>
            }
            <div>
              <ShareButton
                uuid={uuid}
                title={title}
              />
            </div>
          </div>
        </div>

        <div className="data-content">
          <h3 className="xdh3">
            <CustomLink
              to={`/-/kalender/event/${uuid}`}
              ariaLabel={`Zur Veranstaltung: ${title}`}
              title={`Zur Veranstaltung: ${title}`}
            >
              {short_title || title}
            </CustomLink>
          </h3>


          <p dangerouslySetInnerHTML={{ __html: descriptionText }}/>

          <CustomLink
            to={`/-/kalender/event/${uuid}`}
            className="more-link icon icon-custom-chevron-right"
            title={`Zur Veranstaltung: ${title}`}
            ariaLabel={`Mehr über ${title} lesen`}
          >
            Mehr
          </CustomLink>
        </div>


        <div className="event-item_footer">
          <div className="data-addtl">
            <ul className="icon-medium icon-round icon-listing">
              <CategoryIcons vocabularies={vocabularies}/>
            </ul>
          </div>

          <div className="data-footer">
            <SignUpButton event={event}/>
          </div>
        </div>
      </section>
    </li>
  );
};

EventListItemDefault.displayName = 'EventListItemDefault';
export default EventListItemDefault;
