/**
 * Created by Tobias Wahl
 * User: t.wahl on 24.02.2021
 * Copyright: xdot GmbH 2021
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResultsList from './ResultsList';
import ResultsGrid from './ResultsGrid';
import ResultsMap from './ResultsMap';
import { withConfigHoc } from '../../context/GlobalConfigContext';
import IconButton from '../EventActions/IconButton';
import { ResultsCount } from './ResultsCount';
import { List } from '../List';
import SearchResultsFilter from './SearchResultsFilter';

const getResultComp = (type) => {
  const comps = {
    list: ResultsList,
    grid: ResultsGrid
  };
  return comps[type];
};

const SearchResultsBITBW = ({ searchResults = {}, query, onFilterClick, config }) => {
  const [displayType, setDisplayType] = useState('list');
  const { total, results, facets } = searchResults;
  const ResultsComponent = getResultComp(displayType);

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }
  }, []);

  const changeDisplayType = (type) => () => setDisplayType(type);

  return (
    <div className="search-results-wrapper">
      <SearchResultsFilter facets={facets} query={query} onFilterClick={onFilterClick}/>

      <div className="search-results">

        <div className="search-results-content" aria-live="polite">

          <div className="search-results-head">
            <ResultsCount searchResults={searchResults} />

            <List direction="horizontal">
              <li>
                <IconButton
                  icon="th"
                  onClick={changeDisplayType('grid')}
                  title="Anzeigetyp: Tabelle"
                />
              </li>
              <li>
                <IconButton
                  icon="list"
                  onClick={changeDisplayType('list')}
                  title="Anzeigetyp: Liste"
                />
              </li>

            </List>
          </div>

          <ResultsComponent
            data={results}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchResultsBITBW;

SearchResultsBITBW.propTypes = {
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
  onFilterReset: PropTypes.func,
  onDisplayTypeChange: PropTypes.func,
  query: PropTypes.object
};

