/**
 * Created by Nikolai Gorte
 * User: n.gorte on 28.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import EventListItem from '../Listitem/EventListItem';

const ResultsGridDefault = ({ data, total }) => {

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }

    window.scrollTo(0, 0);
  }, []);

  if (!data) {
    return (
      <div className="search-results">
        <div className="search-results-content"  aria-live="polite">
          <span className="xdh4">Es ist ein Fehler aufgetreten</span>
        </div>
      </div>
    );
  }

  if (total === 0) {
    return (
      <div className="search-results">
        <div className="search-results-content"  aria-live="polite">
          <span className="xdh4">Keine Ergebnisse</span>
        </div>
      </div>
    );
  }

  return (
    <div className="results-grid">
      <ul className="event-item-list">
        {
          data.map(item =>
            <EventListItem
              key={item.uuid}
              event={item}
            />
          )
        }
      </ul>
    </div>
  );
};

ResultsGridDefault.propTypes = {
  data: PropTypes.array,
};

export default ResultsGridDefault;


