import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { IconButtonDefault } from './IconButtonDefault';
import { IconButtonEW } from './IconButtonEW';
import { IconButtonBITBW } from './IconButtonBITBW';

const COMPONENTS = {
  default: IconButtonDefault,
  energiewendetage: IconButtonEW,
  bitbw: IconButtonBITBW
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const IconButton = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
IconButton.displayName = 'IconButton';
export default IconButton;

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};