/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 09:25
 */

import React from 'react';
import PropTypes from 'prop-types';

export const Icon = ({ className, icon }) => {

  return (
    <span
      className={`xdicon${className ? ' ' + className : ''}${icon.includes('large') ? ' xdicon-large' : ''}`}
      aria-hidden={true}
    >
      <span className={`icon icon-${icon}`}/>
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string
};
