/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext, withConfigHoc } from '../../context/GlobalConfigContext';

import SearchResultsDefault from './SearchResultsDefault';
import SearchResultsEW from './SearchResultsEW';
import SearchResultsBITBW from './SearchResultsBITBW';

const COMPONENTS = {
  default: SearchResultsDefault,
  energiewendetage: SearchResultsEW,
  bitbw: SearchResultsBITBW
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const SearchResults = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

SearchResults.propTypes = {
  data: PropTypes.array
};

export default SearchResults;
