/**
 * Created by Nikolai Gorte
 * User: n.gorte on 10.10.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

const defaultOptions = {
  slideBy: 1,
  items: 1,
  controls: true,
  controlsPosition: 'bottom',
  navPosition: 'bottom',
  nav: false,
  autoplay: false,
  touch: true,
  mouseDrag: true,
  freezable: true,
  center: false,
  gutter: 24,
  speed: 600,
  loop: false,
  rewind: false,
  lazyload: true,
  lazyloadSelector: '.slide__image',
  arrowKeys: true,
  controlsText: ['<span class="icon-um icon-um--arrow-left"></span>', '<span class="icon-um icon-um--arrow-right"></span>'],
  onInit: function (info) {
    updateInfo(info);
  }
};

const updateInfo = function(info) {
  if (info.navItems) {
    for (var i = 0; i < info.navItems.length; i++) {
      info.navItems[i].setAttribute('aria-label', 'Folie ' + (i + 1));
    }

    info.navItems[info.navCurrentIndex].setAttribute('aria-label', 'Folie ' + (info.navCurrentIndex + 1) + ' (aktuell)');

  }

  info.controlsContainer.setAttribute('aria-label', 'Navigationselemente');
  info.prevButton.setAttribute('aria-label', 'zur vorhergehenden Folie springen');
  info.nextButton.setAttribute('aria-label', 'zur nächsten Folie springen');
  if (info.navContainer) {
    info.navContainer.setAttribute('aria-label', 'Auswahl der Folien')
  }
}

class CarouselEW extends React.Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
    const { children, options, namespace } = this.props;

    this.slider = tns({
      ...defaultOptions,
      container: '#slider_' + namespace + ' .slides-list',
      responsive: {
        0: {
          items: Math.min(1, children.length)
        },
        600: {
          items: Math.min(2, children.length)
        },
        1000: {
          items: Math.min(3, children.length)
        }
      }
    });

    this.slider.events.on('indexChanged', function (info) {
      updateInfo(info);
    });
  }

  componentWillUpdate () {
    this.slider && this.slider.destroy(true);
  }

  componentDidUpdate (prevProps, prevState) {
    const { children, options, namespace } = this.props;

    this.slider = tns({
      ...defaultOptions,
      container: '#slider_' + namespace + ' .slides-list',
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      },
    });

    this.slider.events.on('indexChanged', function (info) {
      updateInfo(info);
    });
  }

  componentWillUnmount () {
    this.slider && this.slider.destroy(true);
  }

  render () {
    const { children, options, namespace } = this.props;

    return (
      <section id={`slider_${namespace}`} className="multibox">
        <div className={'event-container slides-wrapper'}>
          <div className="slides-list" aria-roledescription="Karusell">
            {children}
          </div>
        </div>
      </section>
    );
  }
}

CarouselEW.propTypes = {
  options: PropTypes.object,
  namespace: PropTypes.string
};

export default CarouselEW;
