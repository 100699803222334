/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';

import EventCalendarController from '../controllerViews/EventCalendarController';
import { GlobalConfigContextProvider } from '../context/GlobalConfigContext';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import EventDetailsController from '../controllerViews/EventDetailsController';
import { GlobalStateContextProvider } from '../context/GlobalStateContext';
import { SearchContextProvider } from '../context/SearchContext';
import { QueryParamProvider } from 'use-query-params';

export const EventCalendarWidget = (props) => {
  const location = useLocation();

  let evetnId = '';
  let keywords = '';
  new URLSearchParams(location.search).forEach((value, key) => {
    if (key.indexOf('eventId') > -1) {
      evetnId = value;
    } else if (key.indexOf('keywords') > -1 || key.indexOf('searchTerm') > -1) {
      keywords = value;
    }
  });

  return (
    <GlobalConfigContextProvider config={props}>
      <SearchContextProvider initialQuery={{ keywords }}>
        <GlobalStateContextProvider>
          <Switch>
            <Route exact path="/">
              <QueryParamProvider ReactRouterRoute={Route}>
                {evetnId ? <Redirect to={`/-/kalender/event/${evetnId}`}/> : <EventCalendarController/>}
              </QueryParamProvider>
            </Route>
            <Route path={`/-/kalender/event/:eventId`}>
              <EventDetailsController showCurrentEvents={true}/>
            </Route>
          </Switch>
        </GlobalStateContextProvider>
      </SearchContextProvider>
    </GlobalConfigContextProvider>
  );
};

EventCalendarWidget.displayName = 'EventCalendarWidget';
