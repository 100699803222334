import React from 'react';
import PropTypes from 'prop-types';

export const ActiveFilterItemEW = (
  {
    category,
    onFilterClick
  }
) => {

  const value = category.label;
  const id = category.id
  const title = category.label + (category.description ? ': ' + category.description : '');

  return (
      <li className="search-results-active-filter-item btn btn-tag" title={title}>
        {value}
        <a title="Filter entfernen" href="javascript:void(0);" className="icon-um icon-um--close no-border"  onClick={() => onFilterClick(id)}></a>
      </li>
  );
}

ActiveFilterItemEW.displayName = 'ActiveFilterItemEW';
ActiveFilterItemEW.propTypes = {
  category: PropTypes.object,
  onFilterClick: PropTypes.func
};