/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSearchContext } from '../context/SearchContext';
import { QueryParams, StringParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { removeSearchParam } from '../utils/utils';

const EventCalendarSearchInputEW = ({ id = 'event-search-input', label = '' }) => {
  const { query, setQuery } = useSearchContext();
  const { keywords } = query;
  const queryConfig = {
    keywords: withDefault(StringParam, keywords)
  };
  const history = useHistory();

  const onKeyUp = (e, keywordsUrl) => {
    if (e.keyCode === 13 || e.which === 13) {
      history.replace({search: removeSearchParam(history.location.search, 'page').toString()});
      setQuery({...query, keywords: keywordsUrl});
    }
  };

  const onSubmit = () => {
    history.replace({search: removeSearchParam(history.location.search, 'page').toString()});
    setQuery({...query, keywords});
  };

  return (
    <QueryParams config={queryConfig}>
      {({query: queryUrl, setQuery: setQueryUrl}) => {
        const { keywords } = queryUrl;

        return (
          <div className="search-input-wrapper">
            <label
              className="xdlabel"
              htmlFor={id}
            >
              {label}
            </label>
            <div className="search-input">
              <input
                id={id}
                type="text"
                placeholder="Suche nach Titel, Ort..."
                value={keywords}
                onChange={e => setQueryUrl({keywords: e.target.value})}
                onKeyUp={e => onKeyUp(e, keywords)}
                aria-label="Geben Sie hier einen Suchbegriff ein"
              />
              <button
                className="btn btn-icon"
                onClick={() => onSubmit()}
                aria-label="Suche ausführen"
              >
          <span className="xdicon" aria-hidden="true">
            <span className="icon icon-um icon-um--search"></span>
          </span>
                <span className="sr-only">Suchen</span>
              </button>
            </div>
          </div>
        );
      }}
    </QueryParams>
  );
};

export default EventCalendarSearchInputEW;

EventCalendarSearchInputEW.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
