/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import EventListItemDefault from './EventListItemDefault';
import EventListItemEW from './EventListItemEW';
import EventListItemBITBW from './EventListItemBITBW';

const COMPONENTS = {
  default: EventListItemDefault,
  energiewendetage: EventListItemEW,
  bitbw: EventListItemBITBW
};
const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const EventListItem = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

EventListItem.displayName = 'EventListItem';
export default EventListItem;
