/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect } from 'react';

import EventCalendar from '../components/EventCalendar/EventCalendar';
import SignUpFormController from './SignUpFormController';
import { useSearchContext } from '../context/SearchContext';
import { useGlobalConfigContext } from '../context/GlobalConfigContext';
import dayjs from 'dayjs';

import { useQueryParam, withDefault, ArrayParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { setQueryParams } from '../utils/utils';

const EventCalendarController = () => {
  const { query, search, setQuery, isLoading, results: searchResults } = useSearchContext();
  const { config } = useGlobalConfigContext();
  const defaultDateRangeVal = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 30;
  const history = useHistory();

  const [queryCategoryIds, setQueryCategoryIds] = useQueryParam('categoryIds', withDefault(ArrayParam, []));

  useEffect(() => {
    if (Object.keys(searchResults).length === 0 && history.location.search.length === 0) {
      search(query);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const searchUrl = history.location.search;
      if (searchUrl.length > 0) {
        setQuery(setQueryParams(searchUrl, query));
        search(query);
      }
    }
  }, [query]);

  useEffect(() => {
    let { categoryIds } = query;

    let update = true;

    if (categoryIds.length === queryCategoryIds.length) {
      if (categoryIds.every((val, index) => val === queryCategoryIds[index])) {
        update = false;
      }
    }

    if (update) {
      setQuery({ ...query, categoryIds: queryCategoryIds });
    }

  }, [queryCategoryIds]);

  const onFilterClick = (categoryId) => {
    let { categoryIds } = query;
    if (categoryIds.indexOf(categoryId) === -1) {
      categoryIds.push(categoryId);
    } else {
      categoryIds.splice(categoryIds.indexOf(categoryId), 1);
    }
    setQuery({ ...query, categoryIds });
    setQueryCategoryIds(categoryIds);
    if (history.location.search.length === 0) {
      search();
    }
  };

  const onFilterReset = () => {
    let { categoryIds } = query;
    categoryIds.splice(0, categoryIds.length);
    history.replace({ search: '' });
    const q = {
      ...query,
      categoryIds,
      dateRangeVal: defaultDateRangeVal,
      startTime: config.defaultStartDate.length > 0 ? dayjs(config.defaultStartDate).valueOf() : dayjs().startOf('day').valueOf(),
      endTime: dayjs().add(defaultDateRangeVal, 'days').valueOf(),
      keywords: ''
    };
    setQuery(q);
    setQueryCategoryIds(categoryIds);
    search(q);
  };

  return (
    <>
      <EventCalendar
        query={query}
        searchResults={searchResults}
        onFilterClick={onFilterClick}
        onFilterReset={onFilterReset}
        isLoading={isLoading}
      />

      <SignUpFormController/>
    </>
  );
};

EventCalendarController.displayName = 'EventCalendarController';
export default EventCalendarController;


