/**
 * User: Niko
 * Date: 10.09.2020
 * Time: 12:03
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from '../../context/GlobalStateContext';

export const SignUpButton = ({ event }) => {
  const { setState } = useGlobalStateContext();
  const { require_registration, registrationPossible, waitingList, waitingListFreePlaces, freePlaces } = event;

  const onSignUpClick = () => {
    setState(prevState => ({
      ...prevState,
      selectedEvent: event,
      showSignUpForm: true,
    }));
  };

  const parsePlaces = (places) => {
    const parsedPlaces = parseInt(places);
    if (!isNaN(parsedPlaces)) {
      return parsedPlaces;
    }
    return -1;
  };

  const disableButton = () => {
    if (waitingList) {
      return parsePlaces(waitingListFreePlaces) <= 0;
    }
    if (freePlaces) {
      return parsePlaces(freePlaces) <= 0;
    }
    return false;
  };

  const createButtonText = () => {
    if (waitingList && parsePlaces(waitingListFreePlaces) > 0) {
      return 'Auf Warteliste setzen';
    }
    if (waitingList && parsePlaces(waitingListFreePlaces) <= 0 || freePlaces && parsePlaces(freePlaces) <= 0) {
      return 'Ausgebucht';
    }
    return 'Anmelden';
  };

  return (
    require_registration && registrationPossible ?
      <button
        className="xdbtn btn btn-sign-up"
        onClick={onSignUpClick}
        disabled={disableButton()}
      >
        {createButtonText()}
      </button>
      :
      <></>
  );
};

SignUpButton.propTypes = {
  event: PropTypes.object.isRequired
};
