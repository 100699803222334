import React from 'react';
import { Icon } from '../Icon';
import PropTypes from 'prop-types';

export const IconButtonDefault = ({ icon, title, onClick, children }) => {

  return (
    <button
      className="xdbtn--link xdbtn--icon"
      title={title}
      aria-label={title}
      onClick={onClick}
    >
      {children}
      <Icon icon={icon}/>
    </button>
  );
};

IconButtonDefault.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
