/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '../DateRangePicker';
import SearchInput from '../SearchInput';
import FilterWrapper from '../FilterWrapper';
import SearchResults from '../SearchResults/SearchResults';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { Loader } from '../Loader';
import { useHistory } from 'react-router-dom';

export const EventCalendarDefault = ({
  query, searchResults, onFilterClick,
  onDisplayTypeChange, isLoading, onFilterReset
}) => {
  const history = useHistory();
  const { config } = useGlobalConfigContext();
  const { portletSetupTitle_de_DE } = config;
  const { categoryIds, keywords } = query;
  const defaultDateRange = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 30;
  const [ urlDateRange, setUrlDateRange ] = useState(defaultDateRange);

  useEffect(() => {
    if (history.location.search.length > 0) {
      sessionStorage.setItem("queryParams", history.location.search);
      const urlSearch = new URLSearchParams(history.location.search);
      if (urlSearch.has("dateRange")) {
        setUrlDateRange(parseInt(urlSearch.get("dateRange")));
      }
    } else {
      sessionStorage.removeItem("queryParams");
      setUrlDateRange(defaultDateRange);
    }
  }, [history.location.search]);

  return (
    <div className={"event-calendar"}>
      <h2 className="xdh1">
        {portletSetupTitle_de_DE || 'Veranstaltungskalender'}
      </h2>


      <div className="event-calendar-head">
        <FilterWrapper title="Veranstaltung" collapsible={true} initialCollapsed={window.innerWidth <= 768}>
          {
            (categoryIds.length > 0 || defaultDateRange !== urlDateRange || keywords.length > 0) &&
            <div className="search-results-filter-reset">
              <a
                href="javascript:void(0)"
                role="button"
                onClick={onFilterReset}
              >
                Alle Filter l&ouml;schen
              </a>
            </div>
          }
          <SearchInput/>
          <DateRangePicker/>
        </FilterWrapper>
        <div className="clearfix"/>
      </div>

      <div className="event-calendar-content" role="region">
        <Loader loading={isLoading}/>

        <SearchResults
          query={query}
          searchResults={searchResults}
          onDisplayTypeChange={onDisplayTypeChange}
          onFilterClick={onFilterClick}
        />
      </div>
    </div>
  );
};

EventCalendarDefault.displayName = 'EventCalendarDefault';
EventCalendarDefault.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
};


