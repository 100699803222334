/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { EventCalendarDefault } from './EventCalendarDefault';
import { EventCalendarEW } from './EventCalendarEW';
import { EventCalendarBITBW } from './EventCalendarBITBW';

const COMPONENTS = {
  default: EventCalendarDefault,
  energiewendetage: EventCalendarEW,
  bitbw: EventCalendarBITBW
};
const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const EventCalendar = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
EventCalendar.displayName = 'EventCalendar';
export default EventCalendar;

EventCalendar.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
  onFilterReset: PropTypes.func
};


