import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { SearchResultsFilterDefault } from './SearchResultsFilterDefault';
import { SearchResultsFilterEW } from './SearchResultsFilterEW';
import { SearchResultsFilterBITBW } from './SearchResultsFilterBITBW';

const COMPONENTS = {
  default: SearchResultsFilterDefault,
  energiewendetage: SearchResultsFilterEW,
  bitbw: SearchResultsFilterBITBW
};
const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const SearchResultsFilter = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
SearchResultsFilter.displayName = 'SearchResultsFilter';
export default SearchResultsFilter;

SearchResultsFilter.propTypes = {
  facets: PropTypes.array,
  query: PropTypes.object,
  onFilterClick: PropTypes.func,
  onFilterReset: PropTypes.func
};
