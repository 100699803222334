/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */



import { LiferayClient } from '@convotis/liferay-client/lib/index';

const DEBUG = process.env.NODE_ENV !== 'production';

const url = window.eventManagerServerUrl ||
  // 'https://lr7-test.convotis.com';
  //'http://192.168.31.45:8080'; //Dominik S.
  //'http://192.168.31.6:8080'; //Dominik M. Kalender-ID 31092
  //'http://192.168.31.27:8090'; //Carsten H.
  //'https://veranstaltungstool.convotis.com';
  'https://um-veranstaltungstool.baden-wuerttemberg.de';
  // 'https://www.um-veranstaltungstool.baden-wuerttemberg.de'; // Kalender-ID 40433
  //'https://veranstaltung-bw.de'; // Kalender-ID 40433

//'//https://www.um-veranstaltungstool.baden-wuerttemberg.de'; //Produktiv
/**
 * Custom Liferay-lrc extended for the Liferay-Widget-React-Framework (Lwr-Framework).
 */
class LwrLiferayClient extends LiferayClient {
  invokeWithContext (method, data, context) {
    if (DEBUG) {
      if (data) {
        Object.keys(data).forEach(p => {
          if (typeof data[p] === 'undefined') {
            console.warn('Undefined param: ' + p);
          }
        });
      }
    }

    let v = {
      'serviceContext': {
        'scopeGroupId': this.getScopeGroupId(),
        'attributes': context
      }
    };
    if (arguments.length > 2 && typeof (context) != 'undefined') {
      v.serviceContext.attributes = context;

      v = {
        ...v,
        ...data
      };
    } else {
      v.serviceContext.attributes = data;
    }
    return this.invoke(method, v);
  }

  getScopeGroupId () {
    return window?.Liferay.ThemeDisplay.getScopeGroupId();
  }
}

export const lrc = new LwrLiferayClient({
  url: url
});

