import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import FilterWrapper from '../FilterWrapper';
import FilterList from '../FilterList';
import DateRangePicker from '../DateRangePicker';
import SearchInput from '../SearchInput';

export const SearchResultsFilterBITBW = ({
  facets,
  query,
  onFilterClick
}) => {
  const { config } = useGlobalConfigContext();
  const defaultDateRange = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 830;

  return (
    <div className="bitbw-search-container">
        <div className="event-calendar-search">
          <SearchInput label="Veranstaltung"/>
        </div>

        <div className="search-results-filter">

          {
            <DateRangePicker/>
          }

          <div className="filter-wrapper__categories" aria-live="polite">
            {
              facets && facets.map(f => {
                  if (f.categories && f.categories.length > 0) {
                    return (

                      <FilterWrapper title={f.label} collapsible>
                        <FilterList
                          query={query}
                          facetKey={f.id}
                          facet={f}
                          onFilterClick={onFilterClick}
                          collapsed={false}
                        />
                      </FilterWrapper>
                    );
                  }
                }
              )
            }
          </div>
        </div>
    </div>
  );

};

SearchResultsFilterBITBW.displayName = 'SearchResultsFilterBITBW';
SearchResultsFilterBITBW.propTypes = {
  facets: PropTypes.array,
  query: PropTypes.object,
  onFilterClick: PropTypes.func,
};
