import React from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { Icon } from 'leaflet';
import { mapIconActive } from './SearchResults/ResultsMapEW';
import '../style/components/leaftlet/leaflet.css';

class MapEW extends React.Component {

  constructor (props) {
    super(props);
  }

  render () {
    const { event } = this.props;
    const { latitude, longitude } = event.geolocation;

    const markerIcon = new Icon({
      iconUrl: mapIconActive,
      iconSize: [60, 60]
    });

    return (
      <div>
        <div id="map" role="application">
          <MapContainer
            center={[latitude, longitude]}
            zoom={13}
            scrollWheelZoom={true}
            gestureHandling={true}
            gestureHandlingOptions={
              {
                text: {
                  touch: "Verschieben der Karte mit zwei Fingern",
                  scroll: "Verwende Strg + Scrollen zum Zoomen der Karte",
                  scrollMac: "Verwende \u2318 + Scrollen zum Zoomen der Karte"
                },
                duration: 2000
              }
            }
          >
            <TileLayer
              attribution='&copy; <a href="http://www.bkg.bund.de" target="_blank">Bundesamt für Kartographie und Geodäsie (2022)</a>, <a href="https://sgx.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.html" target="_blank">Datenquellen</a>'
              url="https://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web_light/default/WEBMERCATOR/{z}/{y}/{x}.png"
            />
            <Marker position={[latitude, longitude]} icon={markerIcon} />
          </MapContainer>
        </div>
      </div>
    );
  }
}

MapEW.propTypes = {
  event: PropTypes.object
};

export default MapEW;
