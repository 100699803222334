/**
 * User: Niko
 * Date: 13.03.2020
 * Time: 15:35
 */

import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_STATE = {
  selectedEvent: null,
  showSignUpForm: false,
};

const Context = React.createContext(DEFAULT_STATE);
Context.displayName = 'GlobalStateContext';

export const GlobalStateContextProvider = ({ children }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  return (
    <Context.Provider value={{ state, setState }}>
      {children}
    </Context.Provider>
  );
};

GlobalStateContextProvider.propTypes = {
  config: PropTypes.shape({
    selectedEvent: PropTypes.object,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const useGlobalStateContext = () => {
  const { state, setState } = useContext(Context);

  const setSelectedEvent = useCallback((selectedEvent) => {
    setState(prevSate => ({
      ...prevSate,
      selectedEvent,
    }));
  }, [setState]);

  const setShowSignUpForm = useCallback((showSignUpForm) => {
    setState(prevSate => ({
      ...prevSate,
      showSignUpForm
    }));
  }, [setState]);


  return ({
    ...state,
    setState,
    setSelectedEvent,
    setShowSignUpForm
  });
};
