/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export const FilterWrapperEW = (props) => {
  const { title, children, collapsible, centered, initialCollapsed } = props;
  const history = useHistory();

  const [ collapsed, setCollapsed ] = useState(initialCollapsed);

  const onClick = () => {
    if (collapsible) {
      if (!collapsed) {
        const params = new URLSearchParams(history.location.search);
        if (params.size > 0 && params.has('page')) {
          params.delete('page');
          history.replace({pathname: "/", search: params.toString()});
        }
      }
      setCollapsed(!collapsed);
    }
  }

  return (
    <div className={`filter-wrapper ${centered ? 'flex-center' : ''} ${collapsed ? '' : 'open'}`}>
                <span className="filter-wrapper__title">
                    {collapsible ?
                      <a key="wrapper-header" href="javascript:void(0)" onClick={onClick} role="button"
                         aria-expanded={collapsed ? `false` : `true`}
                         className={collapsed ? `collapsed` : ``}
                         title={collapsed ? 'Filter öffnen' : 'Schließen'}>
                        <span className="inner-title">{title}</span>
                        <span className={`icon-um ${collapsed ? `icon-um--arrow-down` : `icon-um--close`}`}
                              aria-hidden="true"></span>
                      </a>
                      :
                      <span key="wrapper-header" className="inner-title">{title}</span>
                    }
                </span>
      <div className="filter-wrapper__content">
        {!collapsed && children}
      </div>
    </div>
  );
}

FilterWrapperEW.propTypes = {
  title: PropTypes.string,
  collapsible: PropTypes.bool,
  initialCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
