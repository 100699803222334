/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 12:23
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isTrue } from '../../utils/utils';

export const EventRegistration = ({ event, config }) => {
  const { registrationat, registrationatemailaddress, registrationatfirstname, registrationatphonenumber } = event;
  const show = isTrue(config.registrationat) && registrationat && (registrationatemailaddress || registrationatphonenumber);

  if (!show) {
    return null;
  }


  return (
    <div className="event-details__contact">
      <h2 className="xdh3">Anmeldung bei</h2>

      {
        isTrue(config.registrationat) && (registrationat || registrationatfirstname) &&
        <div className="headline--greenline">{registrationat}, {registrationatfirstname}</div>
      }
      <p>
        {
          isTrue(config.registrationatphonenumber) && registrationatphonenumber &&
          <span>
            Tel. <a
            href={'tel:' + registrationatphonenumber}>
                {registrationatphonenumber}
              </a>
          </span>
        }
        {
          isTrue(config.contactphone) && registrationatphonenumber && <br/>
        }
      </p>
      {
        isTrue(config.registrationatemailaddress) && registrationatemailaddress &&
        <a
          href={`mailto:${registrationatemailaddress}`}
          className="btn btn-default no-icon"
        >
          E-Mail schreiben
        </a>
      }
    </div>
  );
};

EventRegistration.propTypes = {
  event: PropTypes.object,
  config: PropTypes.object,
};
