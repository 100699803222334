/**
 * User: Niko
 * Date: 13.03.2020
 * Time: 15:35
 */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GOOGLE_API_KEY } from '../constants/Constants';

export const DEFAULT_CONFIG = {
  googleApiKey: GOOGLE_API_KEY,
  portletId: '',
  config: {},
};

const Context = React.createContext(DEFAULT_CONFIG);
Context.displayName = 'GlobalConfigContext';

export const GlobalConfigContextProvider = ({
  children,
  config,
}) => {
  const [state, setState] = useState({
    ...DEFAULT_CONFIG,
    ...config
  });

  useEffect(() => {
    setState({
      ...DEFAULT_CONFIG,
      ...config
    });
  }, [config]);

  return (
    <Context.Provider value={state}>
      {children}
    </Context.Provider>
  );
};

GlobalConfigContextProvider.propTypes = {
  config: PropTypes.shape({
    appId: PropTypes.string,
    googleApiKey: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const useGlobalConfigContext = () => useContext(Context);

export const withConfigHoc = (WrappedComponent) => {
  return (props) => {
    const config = useGlobalConfigContext();
    return <WrappedComponent {...config} {...props}/>;
  };
};
