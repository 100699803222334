/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect } from 'react';
import EventListItem from '../components/Listitem/EventListItem';
import SignUpFormController from './SignUpFormController';
import { withConfigHoc } from '../context/GlobalConfigContext';
import { useEventInstance } from '../hooks/useEventInstance';
import { Loader } from '../components/Loader';

const _SingleEventWidgetController = ({ config }) => {
  const { eventId } = config;
  const [{ data: event, isLoading, isError, success }, getEventInstance] = useEventInstance(eventId);

  useEffect(() => {
    getEventInstance(eventId);
  }, [eventId]);

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }
  }, []);

  return (
    <div className="current-events-widget single-event-widget">
      <Loader loading={isLoading}/>
      {
        success && event &&
        <div key={event.uuid} className="event-item-wrapper">
          <EventListItem
            event={event}
          />
        </div>
      }

      <SignUpFormController/>
      {
        isError &&
        <div className="alert alert-warning">Bitte ein Event konfigurieren!</div>
      }
    </div>

  );
};

const SingleEventWidgetController = withConfigHoc(_SingleEventWidgetController);
SingleEventWidgetController.displayName = 'SingleEventWidgetController';
export default SingleEventWidgetController;


