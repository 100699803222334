import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import EventMetaEW from './EventMetaEW';
import EventMetaDefault from './EventMetaDefault';

const COMPONENTS = {
  default: EventMetaDefault,
  energiewendetage: EventMetaEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const EventMeta = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
EventMeta.displayName = 'EventMeta';
export default EventMeta;

