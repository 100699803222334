/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import Calendar from 'rc-calendar';
import Picker from 'rc-calendar/lib/Picker';
import deDE from 'rc-calendar/lib/locale/de_DE';
import dayjs from 'dayjs';

import moment from 'moment';
import 'moment/locale/de';

import 'rc-calendar/assets/index.css';
import { Icon } from './Icon';

moment.locale('de');

const DatePicker = ({ id, disabled, value, onChange, placeholder, className }) => {

  const calendar = (
    <Calendar
      locale={deDE}
      defaultValue={dayjs()}
      timePicker={null}
    />
  );

  const val = value ? dayjs(value) : dayjs();

  const setFocus = (open) => {
    if (! open) {
      document.getElementById(id).focus();
    }
  }

  return (
    <Picker
      disabled={disabled || false}
      calendar={calendar}
      onChange={onChange}
      onOpenChange={setFocus}
      value={val}
    >
      {
        ({ value }) =>
          <span className="xd-date-picker">
            <input
              id={id}
              className={className || ''}
              type="text"
              placeholder={placeholder || 'Datum'}
              value={new Date(value).toLocaleDateString()}
              readOnly
            />
            <Icon icon="calendar"/>
          </span>
      }
    </Picker>
  );

};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default DatePicker;
