/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '../DateRangePicker';
import SearchInput from '../SearchInput';
import FilterWrapper from '../FilterWrapper';
import SearchResults from '../SearchResults/SearchResults';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { Loader } from '../Loader';
import Carousel from '../Carousel';
import EventListItem from '../Listitem/EventListItem';

export const CurrentEventsDefault = ({
  query, searchResults, namespace, isEventDetail, isLoading,
}) => {
  const { config } = useGlobalConfigContext();
  const perPage = Number.parseInt(config.perPage || 3);
  const { results } = searchResults;

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }
  }, [isLoading]);

  return (
    <div className="carousel-wrapper">
      {
        results?.length > 0 && isEventDetail &&
        <h2 className="xdh2 xdh2--marginlrb">Weitere Veranstaltungen</h2>
      }
      <Loader loading={isLoading}/>
      {
        results?.length > 0 &&
        <Carousel options={{ perPage }} namespace={namespace}>
          {
            results.map(item =>
              <div
                key={item.uuid}
                className="event-item-wrapper"
              >
                <EventListItem
                  event={item}
                />
              </div>)
          }
        </Carousel>
      }
    </div>
  );
};

CurrentEventsDefault.displayName = 'CurrentEventsDefault';
CurrentEventsDefault.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  isEventDetail: PropTypes.bool,
  namespace: PropTypes.string
};


