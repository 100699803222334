/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import PropTypes from 'prop-types';
import { ListItemImageDefault } from './ListItemImageDefault';
import { ListItemImageEW } from './ListItemImageEW';
import { ListItemImageBITBW } from './ListItemImageBITBW';

const COMPONENTS = {
  default: ListItemImageDefault,
  energiewendetage: ListItemImageEW,
  bitbw: ListItemImageBITBW
};
const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ListItemImage = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

ListItemImage.propTypes = {
  title: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
  copyright: PropTypes.string,
  eventCalendarViewPath: PropTypes.string,
};

ListItemImage.displayName = 'ListItemImage';
export default ListItemImage;
