/**
 * Created by Tobias Wahl
 * User: t.wahl on 22.02.2020
 * Copyright: xdot GmbH 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '../DateRangePicker';
import SearchInput from '../SearchInput';
import FilterWrapper from '../FilterWrapper';
import SearchResults from '../SearchResults/SearchResults';
import SearchResultsBITBW from '../SearchResults/SearchResultsBITBW';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { Loader } from '../Loader';
import SearchResultsFilter from '../SearchResults/SearchResultsFilter';
import { ResultsCount } from '../SearchResults/ResultsCount';

export const EventCalendarBITBW = ({
  query, searchResults, onFilterClick,
  onDisplayTypeChange, isLoading, facets
}) => {
  const { config } = useGlobalConfigContext();
  const { portletSetupTitle_de_DE } = config;

  return (
    <>
      <div className={"event-calendar event-calendar--bitbw"}>
        <h2 className="xdh1">
          {portletSetupTitle_de_DE || 'Veranstaltungssuche'}
        </h2>
  
        {/*<div className="event-calendar-search">
          <SearchInput label="Suche im Veranstaltungskalender"/>
        </div>*/}
  
        {/*<div className="event-calendar-head">*/}
        {/*  <FilterWrapper title="Veranstaltung">*/}
        {/*    <SearchInput/>*/}
        {/*    <DateRangePicker/>*/}
        {/*    <SearchResultsFilter facets={''} query={query} onFilterClick={onFilterClick} />*/}
        {/*  </FilterWrapper>*/}
        {/*  <div className="clearfix"/>*/}
        {/*</div>*/}
  
        <div className="event-calendar-content" role="region">
          <Loader loading={isLoading}/>
  
          <SearchResultsBITBW
            query={query}
            searchResults={searchResults}
            onDisplayTypeChange={onDisplayTypeChange}
            onFilterClick={onFilterClick}
          />
        </div>
      </div>
    </>
  );
};

EventCalendarBITBW.displayName = 'EventCalendarBITBW';
EventCalendarBITBW.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
};


