import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { ActiveFilterItemEW } from './ActiveFilterItemEW';

const COMPONENTS = {
  default: null,
  energiewendetage: ActiveFilterItemEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ActiveFilterItem = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
ActiveFilterItem.displayName = 'ActiveFilterItem';
export default ActiveFilterItem;

ActiveFilterItem.propTypes = {
  category: PropTypes.object,
  onFilterClick: PropTypes.func
};