/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { CurrentEventsDefault } from './CurrentEventsDefault';
import { CurrentEventsEW } from './CurrentEventsEW';
import { CurrentEventsBITBW } from './CurrentEventsBITBW';

const COMPONENTS = {
  default: CurrentEventsDefault,
  energiewendetage: CurrentEventsEW,
  bitbw: CurrentEventsBITBW
};
const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const CurrentEvents = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
CurrentEvents.displayName = 'CurrentEvents';
export default CurrentEvents;

CurrentEvents.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  isEventDetail: PropTypes.bool,
  namespace: PropTypes.string
};


