import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import ResultsListDefault from './ResultsListDefault';
import ResultsListEW from './ResultsListEW';

const COMPONENTS = {
  default: ResultsListDefault,
  energiewendetage: ResultsListEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ResultsList = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

ResultsList.propTypes = {
  data: PropTypes.array
};

export default ResultsList;