import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { ShareButtonDefault } from './ShareButtonDefault';
import { ShareButtonEW } from './ShareButtonEW';
import { ShareButtonBITBW } from './ShareButtonBITBW';

const COMPONENTS = {
  default: ShareButtonDefault,
  energiewendetage: ShareButtonEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

export const ShareButton = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
ShareButton.displayName = 'ShareButton';
ShareButton.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
