import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import ResultsGridDefault from './ResultsGridDefault';
import ResultsGridEW from './ResultsGridEW';

const COMPONENTS = {
  default: ResultsGridDefault,
  energiewendetage: ResultsGridEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ResultsGrid = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

ResultsGrid.propTypes = {
  data: PropTypes.array
};

export default ResultsGrid;