import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../context/GlobalConfigContext';
import EventCalendarSearchInputEW from './SearchInputEW';
import EventCalendarSearchInputDefault from './SearchInputDefault';

const COMPONENTS = {
  default: EventCalendarSearchInputDefault,
  energiewendetage: EventCalendarSearchInputEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const EventCalendarSearchInput = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
EventCalendarSearchInput.displayName = 'EventCalendarSearchInput';
export default EventCalendarSearchInput;

EventCalendarSearchInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};