/**
 * User: Niko
 * Date: 18.06.2020
 * Time: 10:26
 */
import { useCallback } from 'react';
import { useInvoke } from './useInvoke';

const serviceConfig = {
  invokeWithContext: false,
  servicePath: '/registration.registration/add-registration.1',
};


export const useSignUp = (invokeOnInit = false) => {
  const [ state, setConfig ] = useInvoke({
    invokeWithContext: serviceConfig.invokeWithContext,
    initialServicePath: serviceConfig.servicePath,
    initialData: {},
    initialParams: {},
    invokeOnInit,
  });

  const signUp = useCallback(
    (formData) => {
      setConfig(prevState => ({
        ...prevState,
        params: formData,
      }));
    },
    [ setConfig ],
  );


  return [ state, signUp ];
};
