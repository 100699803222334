import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { ActiveFilterEW } from './ActiveFilterEW';

const COMPONENTS = {
  default: null,
  energiewendetage: ActiveFilterEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ActiveFilter = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
ActiveFilter.displayName = 'ActiveFilter';
export default ActiveFilter;

ActiveFilter.propTypes = {
  facets: PropTypes.array,
  query: PropTypes.object,
  onFilterClick: PropTypes.func
};