/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from './Icon';
import { useSearchContext } from '../context/SearchContext';
import { QueryParams, StringParam, withDefault } from 'use-query-params';

const EventCalendarSearchInputDefault = ({ id = 'event-search-input', label = '' }) => {
  const { query, setQuery } = useSearchContext();
  const { keywords } = query;
  const queryConfig = {
    keywords: withDefault(StringParam, keywords)
  };

  const onKeyUp = (e, keywordsUrl) => {
    if (e.keyCode === 13 || e.which === 13) {
      setQuery({...query, keywords: keywordsUrl});
    }
  };

  return (
    <QueryParams config={queryConfig}>
      {
        ({query: queryUrl, setQuery: setQueryUrl}) => {
          const { keywords } = queryUrl;

          return (
            <div className="search-input-wrapper">
              <label
                className="xdlabel"
                htmlFor={id}
              >
                {label}
              </label>
              <div className="search-input">
                <input
                  id={id}
                  type="text"
                  placeholder="Suche nach Titel, Ort..."
                  value={keywords}
                  onChange={e => setQueryUrl({keywords: e.target.value})}
                  onKeyUp={e => onKeyUp(e, keywords)}
                  aria-label="Geben Sie hier einen Suchbegriff ein"
                />
                <button
                  className="xdbtn btn-search"
                  onClick={() => setQuery({...query, keywords})}
                  aria-label="Suche ausführen"
                >
                  <Icon icon="search"/>
                  <span className="icontext">Suchen</span>
                </button>
              </div>
            </div>
          );
        }
      }
    </QueryParams>
  );
};

export default EventCalendarSearchInputDefault;

EventCalendarSearchInputDefault.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
