import dayjs from 'dayjs';

export const humanTruncate = (orgString, maxLength) => {
  if (orgString && orgString.length > maxLength && maxLength > 3) {
    const cutIdx = maxLength - 3;
    let new_str = orgString.substr(0, cutIdx);

    // Can we make a more 'natural' appearing cut?
    const idxCutAtWordEnd = new_str.lastIndexOf(' ');
    const idxCutAtHyphen = new_str.lastIndexOf('-');

    const natCutIdx = Math.max(idxCutAtHyphen, idxCutAtWordEnd);
    // Use natural cut if result is at least 2/3th of maxLength
    new_str = orgString.substr(0, natCutIdx > (cutIdx * 2 / 3) ? natCutIdx : cutIdx);

    return new_str + ' ...';
  }
  return orgString;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isTrue = (val) => val === 'true' || val === true;

export const setQueryParams = (stringParam, query) => {
  const searchUrlParams = new URLSearchParams(stringParam);
  if (searchUrlParams.size > 0) {
    if (searchUrlParams.has('startTime')) {
      query.startTime = dayjs(searchUrlParams.get('startTime')).valueOf();
    }
    if (searchUrlParams.has('endTime')) {
      query.endTime = dayjs(searchUrlParams.get('endTime')).valueOf();
    }
    if (searchUrlParams.has('dateRange')) {
      query.dateRangeVal = parseInt(searchUrlParams.get('dateRange'));
    }
    if (searchUrlParams.has('keywords')) {
      query.keywords = searchUrlParams.get('keywords');
    }
    if (searchUrlParams.has('categoryIds')) {
      query.categoryIds = searchUrlParams.getAll('categoryIds');
    }
  }
  return query;
};

export const removeSearchParam = (search, paramName) => {
  const urlParams = new URLSearchParams(search);
  if (urlParams.has(paramName)) {
    urlParams.delete(paramName);
  }
  return urlParams;
};

