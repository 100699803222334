/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import './style/index.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import duration from 'dayjs/plugin/duration';

import { EventCalendarWidget } from './widgets/EventCalendarWidget';
import { CurrentEventsWidget } from './widgets/CurrentEventsWidget';
import { EventDetailsWidget } from './widgets/EventDetailsWidget';
import { SingleEventWidget } from './widgets/SingleEventWidget';
import { EventMapWidget } from './widgets/EventMapWidget';
import { BrowserRouter as Router } from 'react-router-dom';

dayjs.locale('de');
dayjs.extend(duration);

const WIDGETS = {
  EventCalendarWidget,
  CurrentEventsWidget,
  EventDetailsWidget,
  SingleEventWidget,
  EventMapWidget
};

export const App = (props) => {
  const Widget = WIDGETS[props.widget];
  return (
    <Router basename={props?.config.baseUrl || '/'}>
      <Widget {...props} />
    </Router>
  );
};

App.displayName = 'RootApp';

