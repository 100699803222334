/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResultsList from './ResultsList';
import ResultsGrid from './ResultsGrid';
import ResultsMap from './ResultsMap';
import { withConfigHoc } from '../../context/GlobalConfigContext';
import IconButton from '../EventActions/IconButton';
import { ResultsCalendar } from './ResultsCalendar';
import { List } from '../List';
import SearchResultsFilter from './SearchResultsFilter';
import FilterWrapper from '../FilterWrapper';
import ActiveFilter from '../ActiveFilter/ActiveFilter';

const getResultComp = (type) => {
  const comps = {
    list: ResultsList,
    grid: ResultsGrid,
    map: ResultsMap,
    calendar: ResultsCalendar
  };
  return comps[type];
};

const SearchResultsEW = ({ searchResults, query, onFilterClick, onFilterReset, config }) => {
  const [displayType, setDisplayType] = useState('list');
  const { total, results, facets } = searchResults;
  const ResultsComponent = getResultComp(displayType);

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }
  }, [searchResults]);

  const changeDisplayType = (type) => () => setDisplayType(type);

  return (
    <div className="search-results search-results--ew">
      <div className="search-results-content search-results-content--ew" aria-live="polite">

        <div className="container-max-width">
          <div className="search-results-head content-width--ew search-results-head--ew content-width--ew">
            <SearchResultsFilter facets={facets} query={query} onFilterClick={onFilterClick} onFilterReset={onFilterReset}/>

            <List direction="horizontal">
              <li>
                <IconButton
                  icon="map-marker"
                  onClick={changeDisplayType('map')}
                  title="Anzeigetyp: Karte"
                  active={displayType === 'map'}
                >
                  <span className="sr-only">Karte</span>
                </IconButton>
              </li>
              <li>
                <IconButton
                  icon="th"
                  onClick={changeDisplayType('grid')}
                  title="Anzeigetyp: Tabelle"
                  active={displayType === 'grid'}
                >
                  <span className="sr-only">Tabelle</span>
                </IconButton>
              </li>
              <li>
                <IconButton
                  icon="list"
                  onClick={changeDisplayType('list')}
                  title="Anzeigetyp: Liste"
                  active={displayType === 'list'}
                >
                  <span className="sr-only">Liste</span>
                </IconButton>
              </li>
              {/*
              <li>
                <IconButton
                  icon="calendar"
                  onClick={changeDisplayType('calendar')}
                  title="Anzeigetyp: Kalender"
                  active={displayType === 'calendar'}
                >
                  <span className="sr-only">Kalender</span>
                </IconButton>
              </li>
              */}
            </List>
          </div>

          <div className="search-results-active-filter search-results-active-filter--ew content-width--ew">
            <ActiveFilter
              facets={facets}
              query={query}
              onFilterClick={onFilterClick}
            />
          </div>
        </div>

        <ResultsComponent
          data={results}
          total={total}
        />
      </div>
    </div>
  );
};

export default SearchResultsEW;

SearchResultsEW.propTypes = {
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
  onFilterReset: PropTypes.func,
  onDisplayTypeChange: PropTypes.func,
  query: PropTypes.object
};

