/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 11:53
 */

import React from 'react';
import { Icon } from '../Icon';

const print = () => window.print();

export const PrintButton = () => {

  return (
    <a
      href="javascript:void(0)"
      onClick={print}
      title="Ansicht drucken"
      aria-label="Ansicht drucken"
    >
      <Icon icon="custom-print"/>
    </a>
  );
};

PrintButton.propTypes = {};
