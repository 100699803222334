/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect } from 'react';

import EventDetails from '../components/EventDetails/EventDetails';
import SignUpFormController from './SignUpFormController';
import { withConfigHoc } from '../context/GlobalConfigContext';
import { useEventInstance } from '../hooks/useEventInstance';

const _SingleEventDetailsController = ({ portletId, config, structureFields }) => {
  const { eventId } = config;
  const [{ data: event, isLoading, isError, success }, getEventInstance] = useEventInstance(eventId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [event]);

  useEffect(() => {
    getEventInstance(eventId);
  }, [eventId]);

  return (
    <>
      <EventDetails
        portletId={portletId}
        event={event}
        config={config}
        structureFields={structureFields}
        showCurrentEvents={false}
        showBackLink={false}
      />

      <SignUpFormController/>
    </>
  );
};

const SingleEventDetailsController = withConfigHoc(_SingleEventDetailsController);
SingleEventDetailsController.displayName = 'SingleEventDetailsController';
export default SingleEventDetailsController;


