import React from 'react';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import EventDetailsDefault from './EventDetailsDefault';
import EventDetailsEW from './EventDetailsEW';
import EventDetailsBITBW from './EventDetailsBITBW';

const COMPONENTS = {
  default: EventDetailsDefault,
  energiewendetage: EventDetailsEW,
  bitbw: EventDetailsBITBW
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const EventDetails = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
EventDetails.displayName = 'EventDetails';
export default EventDetails;
