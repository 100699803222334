/**
 * User: Niko
 * Date: 18.06.2020
 * Time: 10:26
 */
import { useCallback } from 'react';
import { useInvoke } from './useInvoke';

const serviceConfig = {
  invokeWithContext: false,
  servicePath: '/event.event/search-events.11',
};


export const useSearch = (query, invokeOnInit = true) => {
  const [ state, setConfig ] = useInvoke({
    invokeWithContext: serviceConfig.invokeWithContext,
    initialServicePath: serviceConfig.servicePath,
    initialData: {},
    initialParams: {
      locale: 'DE_de',
        ...query
    },
    invokeOnInit,
  });

  const search = useCallback(
    (query) => {
      setConfig(prevState => ({
        ...prevState,
        params: {
          ...prevState.params,
          ...query
        },
      }));
    },
    [ setConfig ],
  );


  return [ state, search ];
};
