import React from 'react';
import { Icon } from '../Icon';
import PropTypes from 'prop-types';

export const IconButtonEW = ({ icon, title, onClick, active, children }) => {

  return (
    <button
      className={`xbtn--link xdbtn--icon btn btn-icon ${active ? 'active' : ''}`}
      title={title}
      aria-label={title}
      onClick={onClick}
    >
      {children}
      <Icon icon={icon}/>
    </button>
  );
};

IconButtonEW.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  active: PropTypes.bool
};
