import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../context/GlobalConfigContext';
import CarouselDefault from './CarouselDefault';
import CarouselEW from './CarouselEW';
import CarouselBITBW from './CarouselBITBW';

const COMPONENTS = {
  default: CarouselDefault,
  energiewendetage: CarouselEW,
  bitbw: CarouselBITBW
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const Carousel = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
Carousel.displayName = 'Carousel';
export default Carousel;

Carousel.propTypes = {
  options: PropTypes.object
};