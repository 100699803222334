/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 10:22
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Icon } from '../Icon';
import { withConfigHoc } from '../../context/GlobalConfigContext';

const _ShareButtonBITBW = ({ uuid, title, config }) => {
  const history = useHistory();
  let detailsURL = window.location.origin + history?.createHref({ pathname: `/-/kalender/event/${uuid}` });
  if (config.eventCalendarViewPath) {
    detailsURL = `${window.location.origin}${config.eventCalendarViewPath}/-/kalender/event/${uuid}`;
  }
  const emailSubject = encodeURIComponent(`${window.location.host} - ${title}`);
  const emailBody = encodeURIComponent(`${title} \n${detailsURL}`);
  const href = `mailto:?subject=${emailSubject}&body=${emailBody}`;

  return (

    <a
      className="arrow-link"
      href={href}
      rel="noopener noreferrer"
      title={`Veranstaltung ${title} teilen`}
      aria-label={`Veranstaltung ${title} teilen`}
    >
      <span className="icon-bitbw-share"></span>
      <span className="btn-title sr-only">Teilen</span>
    </a>
  );
};

export const ShareButtonBITBW = withConfigHoc(_ShareButtonBITBW);
ShareButtonBITBW.displayName = 'ShareButtonBITBW';
ShareButtonBITBW.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
