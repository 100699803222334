import React from 'react';
import PropTypes from 'prop-types';

import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { Loader } from '../Loader';
import ResultsMap from '../SearchResults/ResultsMap';
import SearchResultsFilter from '../SearchResults/SearchResultsFilter';
import ActiveFilter from '../ActiveFilter/ActiveFilter';
import { useSearchContext } from '../../context/SearchContext';

export const EventMapEW = ({
  onFilterClick, onFilterReset
}) => {
  const { query, isLoading, results: searchResults } = useSearchContext();

  const { config } = useGlobalConfigContext();
  const { portletSetupTitle_de_DE, eventCalendarViewPath } = config;
  const { total, results, facets } = searchResults;

  const onSelectEvent = (event) => {
    window.location.href = '/' + eventCalendarViewPath + '/-/kalender/event/' + event;
  }

  return (
    <div className="event-map event-map--ew">
      <div className="container-max-width">
        <h2 className="xdh1">
          {portletSetupTitle_de_DE || 'Veranstaltungskarte EW'}
        </h2>
      </div>
      <div className="event-map-content">
        <Loader loading={isLoading}/>

        <div className="search-results search-results--ew">
          <div className="search-results-content search-results-content--ew" aria-live="polite">
            <div className="container-max-width">
              <div className="search-results-head content-width--ew search-results-head--ew">
                <SearchResultsFilter facets={facets} query={query} onFilterClick={onFilterClick} onFilterReset={onFilterReset}/>
              </div>

              <div className="search-results-active-filter search-results-active-filter--ew content-width--ew">
                <ActiveFilter
                  facets={facets}
                  query={query}
                  onFilterClick={onFilterClick}
                />
              </div>
            </div>
          </div>
        </div>

        <ResultsMap
          data={results}
          eventCalendarViewPath={eventCalendarViewPath}
          onSelectEvent={onSelectEvent}
        />

      </div>
    </div>
  );
};

EventMapEW.displayName = 'EventMapEW';
EventMapEW.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
  onFilterReset: PropTypes.func
};
