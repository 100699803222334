/**
 * User: n.gorte
 * Date: 13.08.2018
 * Time: 10:41
 */

import React from 'react';
import PropTypes from 'prop-types';

import { MapContainer, TileLayer, ZoomControl, Marker } from 'react-leaflet';

import { GestureHandling } from 'leaflet-gesture-handling';

import '../style/components/leaftlet/leaflet-gesture-handling.css';
import * as L from 'leaflet';
import { getCategoryMarker } from './CategoryMarker';

class Map extends React.Component {

  constructor (props) {
    super(props);

    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  }

  render () {
    const { event } = this.props;
    const { latitude, longitude } = event.geolocation;
    const routeURL = `https://maps.google.de/maps?daddr=${latitude},${longitude}&t=h&om=0&hl=de`;


    const icon = L.divIcon({ iconSize: [25, 41], iconAnchor: [12, 41], className: `event-marker ${getCategoryMarker(event.vocabularies)}`});

    return (
      <div>
        <div
          id="map"
          role="application"
        >
          <MapContainer
            center={[latitude, longitude]}
            zoom={10}
            zoomControl={false}
            scrollWheelZoom={true}
            gestureHandling={true}
            gestureHandlingOptions={
              {
                text: {
                  touch: 'Verschieben der Karte mit zwei Fingern',
                  scroll: 'Verwende Strg + Scrollen zum Zoomen der Karte',
                  scrollMac: 'Verwende \u2318 + Scrollen zum Zoomen der Karte'
                },
                duration: 2000
              }
            }
          >
            <TileLayer
              attribution='&copy; <a href="http://www.bkg.bund.de" target="_blank">Bundesamt für Kartographie und Geodäsie (2022)</a>, <a href="https://sgx.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.html" target="_blank">Datenquellen</a>'
              url="https://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web_light/default/WEBMERCATOR/{z}/{y}/{x}.png"
            />

            <ZoomControl position="bottomright"/>

            <Marker position={[latitude, longitude]} icon={icon}/>

            <div className="leaflet-top leaflet-left">
              <div className="leaflet-control">
                <div className="event-info-wrapper">
                  {
                    latitude && longitude &&
                    <div className="event-info">
                      <div className="event-info-content">
                        <div className="event-title">{event && (event.short_title || event.title)}</div>
                      </div>

                      <a href={routeURL} target="_blank" className="event-info-icons icon-small">
                                    <span className="xdicon">
                                        <span className="icon icon-nav"/>
                                    </span>
                        <span>Route</span>
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>

          </MapContainer>

        </div>
      </div>
    );
  }
}

Map.propTypes = {
  event: PropTypes.object
};

export default Map;
