/**
 * User: Niko
 * Date: 13.09.2020
 * Time: 12:42
 */

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

export const ResultsCount = ({ searchResults, query }) => {
  const history = useHistory();
  const { total } = searchResults;

  if (history.location.search && query) {
    const search = history.location.search;
    if (history.location.search.includes("startTime") && history.location.search.includes("endTime")) {
      const urlParams = new URLSearchParams(search);
      query.startTime = dayjs(urlParams.get("startTime")).valueOf();
      query.endTime = dayjs(urlParams.get("endTime")).valueOf();
    }
  }

  return (
    <h2 className="xdh4 results-count-headline">
      {
        query?.startTime && query?.endTime && Number.isInteger(total) ?
          `${total} Veranstaltungen gefunden im Zeitraum ${dayjs(query.startTime).format("DD.MM.YYYY")} bis 
          ${dayjs(query.endTime).format("DD.MM.YYYY")}`
          :
          Number.isInteger(total) && `${total} Veranstaltungen zu Ihrer Suche`
      }
    </h2>
  );
};

ResultsCount.propTypes = {
  searchResults: PropTypes.object,
  query: PropTypes.object
};
