import React from 'react';
import { useGlobalConfigContext } from '../context/GlobalConfigContext';
import DateRangeValueSelectionDefault from './DateRangeValueSelectionDefault';
import DateRangeValueSelectionEW from './DateRangeValueSelectionEW';

const COMPONENTS = {
  default: DateRangeValueSelectionDefault,
  energiewendetage: DateRangeValueSelectionEW,
  bitbw: DateRangeValueSelectionDefault
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const DateRangeValueSelection = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

export default DateRangeValueSelection;
