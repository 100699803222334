/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 12:23
 */

import React from 'react';
import EventOrganizerDefault from './EventOrganizerDefault';
import EventOrganizerEW from './EventOrganizerEW';
import EventOrganizerBITBW from './EventOrganizerBITBW';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';

const COMPONENTS = {
  default: EventOrganizerDefault,
  energiewendetage: EventOrganizerEW,
  bitbw: EventOrganizerBITBW
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const EventOrganizer = ({event}) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component config={config} event={event}/>
  );
};
EventOrganizer.displayName = 'EventOrganizer';
export default EventOrganizer;
