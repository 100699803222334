/**
 * Created by Nikolai Gorte
 * User: n.gorte on 28.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { ResultsMapDefault } from './ResultsMapDefault';
import { ResultsMapEW } from './ResultsMapEW';

const COMPONENTS = {
  default: ResultsMapDefault,
  energiewendetage: ResultsMapEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ResultsMap = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

ResultsMap.propTypes = {
  data: PropTypes.array,
  onSelectEvent: PropTypes.func,
};

export default ResultsMap;
