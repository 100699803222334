/**
 * User: Niko
 * Date: 13.09.2020
 * Time: 13:12
 */

import React from 'react';
import PropTypes from 'prop-types';

export const ResultsCalendar = () => {

  return (
    <div>
      Kalender Platzhalter
    </div>
  );
};

ResultsCalendar.propTypes = {
  data: PropTypes.array,
};
