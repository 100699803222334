/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 12:23
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isTrue } from '../../utils/utils';

export const EventOrganizerBITBW = ({ event, config }) => {
  const { contactname, contactphone, contactemail } = config;
  const { contact_email, contact_name, contact_phone, organizer, ddm_structure_key } = event;
  const show = isTrue(contactname) || isTrue(contactphone) || isTrue(config.organizer) || isTrue(contactemail);

  if (!show) {
    return null;
  }

  let emailButtonText = 'E-Mail schreiben';
  if (ddm_structure_key === 'UNSER_NECKAR' || ddm_structure_key === 'UM_ERLEBNISSE') {
    emailButtonText = 'Per E-Mail anmelden';
  }

  return (
    <address className="event-details__contact">
      <h4 className="xdh3">Ihre Kontaktperson</h4>
      {
        isTrue(config.organizer) && organizer &&
        <span>Veranstalter: {organizer}<br/></span>
      }
      <p>
        {
          isTrue(contactname) && contact_name &&
          <div className="headline--greenline">{contact_name}</div>
        }
        {
          isTrue(contactphone) && contact_phone &&
          <React.Fragment>
            <span>Tel. <a href={'tel:' + contact_phone}>{contact_phone}</a></span>
            <br/>
          </React.Fragment>
        }
      </p>
      {
        isTrue(contactemail) && contact_email &&
        <a href={`mailto:${contact_email}`} className="btn btn-default no-icon">{emailButtonText}</a>
      }
    </address>
  );
};

EventOrganizerBITBW.propTypes = {
  event: PropTypes.object,
  config: PropTypes.object,
};

export default EventOrganizerBITBW;