/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */


import React from 'react';
import { Icon } from './Icon';

const CategoryIcons = ({ vocabularies }) => {

  const icons = [];
  vocabularies && vocabularies.forEach(v => {
    const { categories } = v;
    categories && categories.forEach(c => {
      if (c.class) {
        icons.push(c);
      }
    });
  });

  return (
    <div className="data-addtl icon-medium icon-round icon-listing">
      <ul className="icon-links" aria-label="Merkmale der Veranstaltung">
        {
          icons.map(icon => {
              const title = icon.label + (icon.description ? (': ' + icon.description) : '');
              return (
                <li
                  key={icon.label}
                  title={title}
                  aria-label={title}
                >
                  <Icon icon={`custom-${icon.class}`}/>
                </li>
              );
            }
          )
        }
      </ul>
    </div>
  );
};

export default CategoryIcons;
