/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';

import FilterListItem  from './FilterListItem';

const FilterList = ({ query, facetKey, facet, onFilterClick }) => {
  const { categories } = facet;

  return (
    <ul className="unstyled filter-list">
      {
        categories && categories.map((category, i) => {
          return (
            <FilterListItem
              category={category}
              facetKey={facetKey}
              onFilterClick={onFilterClick}
              index={i}
            />
          )
        })
      }

    </ul>
  );
};

export default FilterList;
