/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';

import CurrentEventsController from '../controllerViews/CurrentEventsController';
import { GlobalConfigContextProvider } from '../context/GlobalConfigContext';
import { GlobalStateContextProvider } from '../context/GlobalStateContext';

export const CurrentEventsWidget = (config) => {

  return (
    <GlobalConfigContextProvider config={config}>
      <GlobalStateContextProvider>
        <CurrentEventsController/>
      </GlobalStateContextProvider>
    </GlobalConfigContextProvider>
  );
};

CurrentEventsWidget.displayName = 'CurrentEventsWidget';

