import React from 'react';

export class StaticMapTopPlus extends React.Component{

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <img src="https://sgx.geodatenzentrum.de/wms_topplus_open?service=wms&version=1.3.0&request=GetMap&Layers=web_light&STYLES=default&CRS=EPSG:4326&bbox=47.52947274458571,3.9327241759747267,49.99117270827111,12.628402886912227&width=1920&Height=680&Format=image/png" className="no-results-img" alt="" />
    );
  }
}

export default StaticMapTopPlus;
