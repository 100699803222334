/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';

import SingleEventWidgetController from '../controllerViews/SingleEventWidgetController';
import { GlobalConfigContextProvider } from '../context/GlobalConfigContext';
import { GlobalStateContextProvider } from '../context/GlobalStateContext';

export const SingleEventWidget = (config) => {

  return (
    <GlobalConfigContextProvider config={config}>
      <GlobalStateContextProvider>
        <SingleEventWidgetController/>
      </GlobalStateContextProvider>
    </GlobalConfigContextProvider>
  );
};

SingleEventWidget.displayName = 'SingleEventWidget';
