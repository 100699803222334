/**
 * User: Niko
 * Date: 10.09.2020
 * Time: 15:47
 */

import React from 'react';
import PropTypes from 'prop-types';

export const Loader = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="loading-animation"/>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool
};
