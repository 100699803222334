/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 11:58
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';

const icalEvent = (eventId) => () => {
  const downloadURL = window.eventManagerServerUrl + '?p_p_id=de_xdot_um_event_ical_export_EventIcalExportPortlet&p_p_lifecycle=2&p_p_state=maximized&p_p_mode=view&p_p_resource_id=%2Fexport%2Fical&p_p_cacheability=cacheLevelPage&_de_xdot_um_event_ical_export_EventIcalExportPortlet_bookingId=' + eventId;
  window.open(downloadURL, '_blank');
};

export const CalendarButton = ({ uuid }) => {

  return (
    <a
      href="javascript:void(0)"
      onClick={icalEvent(uuid)}
      title="In Kalender kopieren"
      aria-label="In Kalender kopieren"
    >
      <Icon icon="custom-calendar"/>
    </a>
  );
};

CalendarButton.propTypes = {
  uuid: PropTypes.string.isRequired
};
