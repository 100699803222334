
export const getCategoryMarker = (vocabularies) => {
console.log(vocabularies)
  const markers = [];
  vocabularies && vocabularies.forEach(v => {
    const { categories } = v;
    categories && categories.forEach(c => {
      if (c.mapMarker) {
        markers.push(c.mapMarker);
      }
    });
  });

  return (markers.join(" "));
};

