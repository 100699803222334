import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { ActiveFilterListEW } from './ActiveFilterListEW';

const COMPONENTS = {
  default: null,
  energiewendetage: ActiveFilterListEW,
  bitbw: null
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const ActiveFilterList = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};
ActiveFilterList.displayName = 'ActiveFilterList';
export default ActiveFilterList;

ActiveFilterList.propTypes = {
  categoryIds: PropTypes.array,
  categories: PropTypes.array,
  onFilterClick: PropTypes.func
};