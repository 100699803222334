/**
 * User: Niko
 * Date: 10.09.2020
 * Time: 14:34
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withConfigHoc } from '../context/GlobalConfigContext';

const _CustomLink = ({ children, config, to, className = '', title = '', ariaLabel = '' }) => {
  const { eventCalendarViewPath, baseUrl } = config;

  let url = eventCalendarViewPath ? eventCalendarViewPath : baseUrl;
  if (url.endsWith("/")) {
    url = url.substring(0, url.length - 1);
  }

  if (url) {
    return (
      <a
        className={className}
        href={url + to}
        title={title}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      className={className}
      to={to}
      title={title}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  );
};

export const CustomLink = withConfigHoc(_CustomLink);
CustomLink.displayName = 'CustomLink';
CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};
