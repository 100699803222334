/**
 * Created by Nikolai Gorte
 * User: n.gorte on 28.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EventListItem from '../Listitem/EventListItem';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { NumberParam, QueryParams, withDefault } from 'use-query-params';

const ResultsListEW = ({ data, total }) => {
  const { config } = useGlobalConfigContext();

  const deltas = [10, 20, 40, 60];

  const [pageNumber, setPageNumber]= useState(1);
  const [pageCount, setPageCount]= useState(5);

  const queryConfig = {
    page: withDefault(NumberParam, 1)
  };

  const handlePrev = (setQuery) => {
    if (pageNumber === 1) return;
    const page = pageNumber - 1;
    setPageNumber(page);
    setQuery({page});
  }

  const numberOfPages = () => {
    return Math.ceil(data.length / pageCount);
  }

  const handleNext = (setQuery) => {
    if (pageNumber <= (numberOfPages() - 1)) {
      const page = pageNumber + 1;
      setPageNumber(page);
      setQuery({page});
    }
  }

  const createPages = (setQuery) => {
    let children = []
    if (numberOfPages() < 5) {
      for (let i = 1; i <= numberOfPages(); i++) {
        let ariaProps = {}
        if (pageNumber === i) {
          ariaProps['aria-current'] = 'page';
        }
        children.push(<li className="page-item"><a {...ariaProps} onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                   href="javascript:void(0);"
                                                   className={(pageNumber === i) ? 'active page-link' : 'page-link'}>{i}</a></li>);
      }
    } else if (pageNumber === 1) {
      children.push(<li className="page-item"><a aria-current="page" onClick={() => {setPageNumber(1);
        setQuery({page: 1})}}
                                                 href="javascript:void(0);"
                                                 className="active page-link">1</a></li>);

      children.push(<li className="page-item"><a onClick={() => {setPageNumber(2); setQuery({page: 2});}}
                                                 href="javascript:void(0);"
                                                 className="page-link">{2}</a></li>);

      children.push(<li className="page-item"><a onClick={() => {setPageNumber(3); setQuery({page: 3});}}
                                                 href="javascript:void(0);"
                                                 className="page-link">{3}</a></li>);

      let intermediatePages = []

      for (let i = 4; i <= numberOfPages() - 1; i++) {
        intermediatePages.push(<li><a onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                   href="javascript:void(0);"
                                                   className="dropdown-icon">{i}</a></li>);
      }

      children.push(<li className="dropdown page-item">
        <a className="dropdown-toggle page-link page-link" href="javascript:void(0);" data-toggle="liferay-dropdown">
          <span aria-hidden="true">...</span>
        </a>
        <div className="dropdown-menu dropdown-menu-top-center">
          <ul className="inline-scroller link-list">
            {intermediatePages}
          </ul>
        </div>
      </li>);

      children.push(<li className="page-item"><a onClick={() => {
        const pageNumber = numberOfPages();
        setPageNumber(pageNumber);
        setQuery({page: pageNumber});
      }}
                                                 href="javascript:void(0);"
                                                 className="page-link">{numberOfPages()}</a></li>);
    } else if (pageNumber === numberOfPages()) {
      children.push(<li className="page-item"><a onClick={() => {setPageNumber(1); setQuery({page: 1});}}
                                                 href="javascript:void(0);"
                                                 className="page-link">{1}</a></li>);
      let intermediatePages = []

      for (let i = 2; i <= numberOfPages() - 3; i++) {
        intermediatePages.push(<li><a onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                            href="javascript:void(0);"
                                                            className="dropdown-icon">{i}</a></li>);
      }

      children.push(<li className="dropdown page-item">
        <a className="dropdown-toggle page-link page-link" href="javascript:void(0);" data-toggle="liferay-dropdown">
          <span aria-hidden="true">...</span>
        </a>
        <div className="dropdown-menu dropdown-menu-top-center">
          <ul className="inline-scroller link-list">
            {intermediatePages}
          </ul>
        </div>
      </li>);

      children.push(<li className="page-item"><a onClick={() => {
        const pageNumber = numberOfPages() - 2;
        setPageNumber(pageNumber);
        setQuery({page: pageNumber})
      }}
                                                 href="javascript:void(0);"
                                                 className="page-link">{numberOfPages() - 2}</a></li>);

      children.push(<li className="page-item"><a onClick={() => {
        const pageNumber= numberOfPages() - 1;
        setPageNumber(pageNumber);
        setQuery({page: pageNumber});
      }}
                                                 href="javascript:void(0);"
                                                 className="page-link">{numberOfPages() - 1}</a></li>);

      children.push(<li className="page-item"><a aria-current="page" onClick={() => {
        const pageNumber = numberOfPages();
        setPageNumber(pageNumber);
        setQuery({page: pageNumber});
      }}
                                                 href="javascript:void(0);"
                                                 className="active page-link">{numberOfPages()}</a></li>);

    } else {
      children.push(<li className="page-item"><a onClick={() => {setPageNumber(1); setQuery({page: 1});}}
                                                 href="javascript:void(0);"
                                                 className="page-link">{1}</a></li>);

      let intermediatePages = []

      for (let i = 2; i < (numberOfPages() > (pageNumber - 1) ? pageNumber - 1 : numberOfPages()); i++) {
        if ((pageNumber - 3) > 1) {
          intermediatePages.push(<li><a onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                              href="javascript:void(0);"
                                                              className="dropdown-icon">{i}</a></li>);
        } else {
          children.push(<li className="page-item"><a onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                              href="javascript:void(0);"
                                                              className="page-link">{i}</a></li>);
        }
      }

      if ((pageNumber - 3) > 1) {
        children.push(<li className="dropdown page-item">
        <a className="dropdown-toggle page-link page-link" href="javascript:void(0);" data-toggle="liferay-dropdown">
          <span aria-hidden="true">...</span>
        </a>
        <div className="dropdown-menu dropdown-menu-top-center">
          <ul className="inline-scroller link-list" data-max-index={pageNumber - 1}>
            {intermediatePages}
          </ul>
        </div>
      </li>);
      }

      if ( (pageNumber - 1) > 1) {
        children.push(<li className="page-item"><a onClick={() => {
          const page = pageNumber - 1;
          setPageNumber(page);
          setQuery({page});
        }}
                                                   href="javascript:void(0);"
                                                   className="page-link">{pageNumber - 1}</a></li>);
      }

      children.push(<li className="page-item"><a aria-current="page" onClick={() => {setPageNumber(pageNumber); setQuery({page: pageNumber});}}
                                                 href="javascript:void(0);"
                                                 className="active page-link">{pageNumber}</a></li>);

      if ( (pageNumber + 1) < numberOfPages()) {
        children.push(<li className="page-item"><a onClick={() => {
          const page = pageNumber + 1;
          setPageNumber(page);
          setQuery({page});
        }}
                                                   href="javascript:void(0);"
                                                   className="page-link">{pageNumber + 1}</a></li>);
      }

      let remainingPages = ((numberOfPages() - (pageNumber + 2)) < numberOfPages()) ? (numberOfPages() - (pageNumber + 2)) : numberOfPages();

      intermediatePages = []

      for (let i = pageNumber + 2; i < ((pageNumber + 2) + remainingPages); i++) {
        if ((pageNumber + 3) < numberOfPages()) {
          intermediatePages.push(<li><a onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                              href="javascript:void(0);"
                                                              className="dropdown-icon">{i}</a></li>);
        } else {
          children.push(<li className="page-item"><a onClick={() => {setPageNumber(i); setQuery({page: i});}}
                                                     href="javascript:void(0);"
                                                     className="page-link">{i}</a></li>);
        }
      }

      if ((pageNumber + 3) < numberOfPages()) {
        children.push(<li className="dropdown page-item">
        <a className="dropdown-toggle page-link page-link" href="javascript:void(0);" data-toggle="liferay-dropdown">
          <span aria-hidden="true">...</span>
        </a>
        <div className="dropdown-menu dropdown-menu-top-center">
          <ul className="inline-scroller link-list" data-current-index={pageNumber + 2}>
            {intermediatePages}
          </ul>
        </div>
      </li>);
      }

      children.push(<li className="page-item"><a onClick={() => {
        const pageNumber = numberOfPages();
        setPageNumber(pageNumber);
        setQuery({page: pageNumber});
      }}
                                                 href="javascript:void(0);"
                                                 className="page-link">{numberOfPages()}</a></li>);

    }

    return children;
  }

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }

    window.scrollTo(0, 0);
  }, [pageNumber, pageCount]);

  if (!data) {
    return;
    // return (
    //   <div className="search-results container-max-width">
    //     <div className="search-results-content">
    //       <span className="xdh4">Es ist ein Fehler aufgetreten</span>
    //     </div>
    //   </div>
    // );
  }

  if (total === 0) {
    return (
      <div className="empty-search-results">
        <img src={config.emptyResultsImage} className="no-results-img" alt="" />
        <h3 className="text">Keine Ergebnisse gefunden.</h3>
      </div>
    );
  }

  return (
    <QueryParams config={queryConfig}>
      {({query, setQuery}) => {
        const { page } = query;
        if (pageNumber !== page) {
          setPageNumber(page);
        }

        return (
          <div className="results-list container-max-width">
            <ul className="event-item-list">
              {
                data.slice((pageNumber - 1) * pageCount, (pageNumber * pageCount)).map(item =>
                  <EventListItem
                    key={item.uuid}
                    event={item}
                  />)
              }
            </ul>
            <div className="taglib-page-iterator">
              <div className="pagination-bar page-selector">
                <div className="dropdown">
                  <a className="dropdown-toggle" data-toggle="liferay-dropdown" href="javascript:;" role="button">
                    {pageCount} Eintr&auml;ge.

                    <span className="sr-only">Pro Seite</span>

                    <span className="icon-um icon-um--arrow-down" aria-hidden="true"></span>
                  </a>

                  <ul className="dropdown-menu">
                    {deltas.map(delta => (
                      <li>
                        <a className="dropdown-icon" href="javascript:void(0);" onClick={() => setPageCount(delta)}>
                          {delta}

                          <span className="sr-only">Eintr&auml;ge per Seite.</span>
                        </a>
                      </li>)
                    )}
                  </ul>
                </div>

                <p className="pagination-results">
                  Zeige {(pageNumber - 1) * pageCount + 1} bis {Math.min(pageNumber * pageCount, data.length)} von {data.length} Eintr&auml;gen.
                </p>
              </div>
              <div className="pagination-bar pages-list">
                {
                  <div className="prev">
                    <a disabled={pageNumber === 1} href="javascript:void(0);" className="btn btn-simple"
                       aria-label="Eine Seite zurück" title="Eine Seite zurück" onClick={() => handlePrev(setQuery)}>
                      <span className="icon-um icon-um--chevron-left no-border" aria-hidden="true"></span>
                      <span className="btn-title"> Zur&uuml;ck </span>
                    </a>
                  </div>
                }
                <ul aria-label="Blättern" className="pagination">
                  {createPages(setQuery)}
                </ul>
                {
                  <div className="next">
                    <a disabled={pageNumber === numberOfPages()} href="javascript:void(0);" className="btn btn-simple"
                       aria-label="Eine Seite vor" title="Eine Seite vor" onClick={() => handleNext(setQuery)}>
                      <span className="btn-title"> Weiter </span>
                      <span className="icon-um icon-um--chevron-right no-border" aria-hidden="true"></span>
                    </a>
                  </div>
                }
              </div>
            </div>
          </div>
        );
      }}
    </QueryParams>
  );
};

ResultsListEW.propTypes = {
  data: PropTypes.array,
};

export default ResultsListEW;
