/**
 * User: Niko
 * Date: 17.06.2020
 * Time: 08:20
 */

import { useEffect, useReducer, useState } from 'react';
import { lrc } from '../utils/lr-client-instance';

const INVOKE_INIT = 'INVOKE_INIT';
const INVOKE_SUCCESS = 'INVOKE_SUCCESS';
const INVOKE_FAIL = 'INVOKE_FAIL';
const INITIAL_RUN = 'INITIAL_RUN';

const invokeReducer = (state, action) => {
  const { payload, options, type } = action;
  switch (type) {
    case INITIAL_RUN:
      return {
        ...state,
        initialRun: false,
      };
    case INVOKE_INIT:
      return {
        ...state,
        isLoading: true,
        success: false,
        isError: false,
      };
    case INVOKE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        success: true,
        data: options?.appendData ? [ ...state.data, ...payload ] : payload,
      };
    case INVOKE_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        isError: true,
        data: state.data,
      };
    default:
      throw new Error('UNKNOWN ACTION TYPE: ' + type);
  }
};

export const useInvoke = ({
                            initialServicePath,
                            invokeWithContext,
                            initialData,
                            initialParams,
                            invokeOnInit = true,
                            appendData = false,
                          }) => {
  const [ config, setConfig ] = useState({
    servicePath: initialServicePath,
    invokeWithContext,
    params: initialParams,
    invokeOnInit,
    appendData,
  });

  const [ state, dispatch ] = useReducer(invokeReducer, {
    initialRun: true,
    isLoading: false,
    isError: false,
    success: false,
    data: initialData,
  });

  useEffect(() => {
    let stopDispatch = false;

    const invoke = async () => {
      dispatch({ type: INVOKE_INIT });
      const invokeFunc = config.invokeWithContext ? lrc.invokeWithContext : lrc.invoke;

      try {
        const result = await invokeFunc(config.servicePath, config.params);
        if (!stopDispatch) {
          dispatch({
            type: INVOKE_SUCCESS,
            payload: result,
            options: { appendData: config.appendData },
          });
        }
      } catch (error) {
        if (!stopDispatch) {
          dispatch({ type: INVOKE_FAIL });
        }
      }
    };

    if (config.invokeOnInit || !state.initialRun) {
      invoke();
    }

    if(state.initialRun){
      dispatch({ type: INITIAL_RUN });
    }

    // prevent setting state on an unmounted component
    return () => {
      stopDispatch = true;
    };
  }, [ config ]);

  return [ state, setConfig ];
};
