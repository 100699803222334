/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../context/GlobalConfigContext';
import { FilterWrapperDefault } from './FilterWrapperDefault';
import { FilterWrapperEW } from './FilterWrapperEW';
import { FilterWrapperBITBW } from './FilterWrapperBITBW';

const COMPONENTS = {
  default: FilterWrapperDefault,
  energiewendetage: FilterWrapperEW,
  bitbw: FilterWrapperBITBW
};

const getVariant = (variant) => COMPONENTS[variant] || COMPONENTS.default;

const FilterWrapper = (props) => {
  const { config } = useGlobalConfigContext();
  const { variant } = config;
  const Component = getVariant(variant);
  return (
    <Component {...props} config={config}/>
  );
};

FilterWrapper.propTypes = {
  title: PropTypes.string,
  collapsible: PropTypes.bool,
  initialCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default FilterWrapper;
